import gql from "graphql-tag";

const meDetailsParams = `
  me {
    id
    photo
    age
    gender
    first_name
    last_name
    date_of_birth
    mobile
    mobile_verified
    email
    email_verified
    email_subscribed
    sms_subscribed
    whatsapp_subscribed
    whatsapp_chatbot_subscribed
    alternate_mobile
    referral_code
    verified_details {
      mobile
      verified_by_candidate
    }
    stakeholder {
      ... on Candidate {
        id
        profile_completion
        resume
        total_experience
        current_company
        vaccination_status
        is_experienced
        fa_experience {
          id
          functional_area {
            id
            name
            image
          }
          experience
        }
        educations {
          id
          institute
          place {
            short_formatted_address
          }
          year_of_passing
          degree
          specialization
          proficiency_level
        }
        languages {
          id
          language {
            id
            name
          }
          proficiency
          modes
        }
        skill_ratings {
          id
          score
          skill {
            id
            name
          }
        }
        work_experience {
          id
          functional_area {
            id
            name
          }
          job_title
          started_from
          worked_till
          till_now
          work_description
          company_name
          duration
        }
        expectation {
          notice_period
          preferred_cities {
            id
            name
          }
        }
        functional_areas {
          id
          name
        }
        salary {
          id
          current_salary
          current_salary_format
          expected_salary
          expected_salary_format
        }
        address {
          id
          address_text
          place {
            location
            place_id
            short_formatted_address
          }
          locality {
            id
            name
            latitude
            longitude
            city {
              id
              name
            }
          }
        }
        generated_resume_urls{
          image
          pdf
        }
      }
    }
  }
`;

// Query to load all data required on Profile Page
export const GET_PROFILE_DETAILS = gql`
  query userDetails($candId: ID!, $userId: String) {
    ${meDetailsParams}
    getCandidateDocuments(candidate_id: $candId) {
      driving_license {
        id
        proof
      }
      aadhaar {
        id
        proof
        proof_extra
      }
      pan {
        id
        proof
      }
      voter_id {
        id
        proof
      }
      bank_account {
        id
        proof
      }
    }
    getOwnershipList: listCandidateOwnerships(candidate_id: $candId) {
      id
      name
      category
      is_owned
    }
    social_getUserInfo(payload: { userId: $userId }) {
      id
      role
      jobRole
      education
      name
      location
      workExperience
      organization
      photo
      email
      gender
      isFollowing
      totalFollowing
      totalFollowers
    }
  }
`;

export const GET_LANGUAGES = gql`
  query {
    listLanguages {
      id
      name
    }
  }
`;

// Query to load only user details
export const GET_USER_DETAILS = gql`
  query userDetails {
    ${meDetailsParams}
  }
`;

// Query to load only email details used in editContactDetails page
export const GET_EMAIL_DETAILS = gql`
  query emailDetails {
    me {
      email
      email_verified
    }
  }
`;

export const CREATE_EDIT_MUTATION = (returnFields) => gql`
  mutation updateUserDetails($cID: ID!, $payload: CandidateEditInput!) {
    mutate(type: CandidateMutation, id: $cID) {
      ... on CandidateMutation {
        id
        edit(payload: $payload) {
         ${returnFields}
        }
      }
    }
  }
`;

export const USER_DETAILS = (candID) => {
  if (candID) {
    return gql`
      query userDetails($candId: ID!) {
        ${meDetailsParams}
        getCandidateDocuments(candidate_id: $candId) {
          driving_license {
            id
            proof
          }
          aadhaar {
            id
            proof
            proof_extra
          }
          pan {
            id
            proof
          }
          voter_id {
            id
            proof
          }
          bank_account {
            id
            proof
          }
        }
        getOwnershipList: listCandidateOwnerships(candidate_id: $candId) {
          id
          name
          category
          is_owned
        }
      }
    `;
  }

  return gql`
    query userDetails {
      ${meDetailsParams}
    }
  `;
};

export const UPLOAD_DOCUMENTS = gql`
  mutation uploadDocuments($cID: ID, $payload: CandidateDocumentInput) {
    mutate(type: CandidateMutation, id: $cID) {
      ... on CandidateMutation {
        id
        document {
          upload(payload: $payload) {
            driving_license {
              id
              proof
            }
            aadhaar {
              id
              proof
              proof_extra
            }
            pan {
              id
              proof
            }
            voter_id {
              id
              proof
            }
            bank_account {
              id
              proof
            }
          }
        }
      }
    }
  }
`;

export const LOCALITY_SEARCH = gql`
  query localitySearch(
    $queryType: LocalitySearchType!
    $Latitude: Float
    $Longitude: Float
    $query: String
    $cityId: Int
  ) {
    searchLocality(
      query_type: $queryType
      latitude: $Latitude
      longitude: $Longitude
      query: $query
      city_id: $cityId
    ) {
      id
      name
      latitude
      longitude
      city {
        id
        name
      }
    }
  }
`;

export const UPDATE_OWNERSHIP = gql`
  mutation updateOwnership($cID: ID!, $payload: CandidateEditInput!) {
    mutate(type: CandidateMutation, id: $cID) {
      ... on CandidateMutation {
        id
        edit(payload: $payload) {
          id
          misc_ownership {
            id
            name
            category
            is_owned
          }
        }
      }
    }
  }
`;

export const UPLOAD_FILE = gql`
  mutation uploadFile($file: Upload!) {
    uploadFile(file: $file, type: GENERAL) {
      response
      relativePath
    }
  }
`;

export const UPLOAD_RESUME = gql`
  mutation uploadResume($cID: ID!, $file: Upload!) {
    mutate(type: CandidateMutation, id: $cID) {
      ... on CandidateMutation {
        id
        resume {
          add(file: $file)
        }
      }
    }
  }
`;

export const UPDATE_PHOTO = gql`
  mutation updatePhoto($cID: ID!, $payload: CandidateEditInput!) {
    mutate(type: CandidateMutation, id: $cID) {
      ... on CandidateMutation {
        id
        edit(payload: $payload) {
          id
          user {
            id
            photo
          }
        }
      }
    }
  }
`;

export const DELETE_RESUME = gql`
  mutation deleteResume($cID: ID!) {
    mutate(type: CandidateMutation, id: $cID) {
      ... on CandidateMutation {
        id
        resume {
          delete
        }
      }
    }
  }
`;

export const LIST_FUNCTIONAL_AREAS = gql`
  query FANames {
    listFunctionalAreas(first: 200) {
      totalCount
      edges {
        id
        name
        image
        category_name
      }
    }
  }
`;

export const SUGGEST_SKILLS = gql`
  query skillNames($query: String!) {
    suggestSkills(query: $query) {
      id
      name
    }
  }
`;

export const UPDATE_SKILLS = gql`
  mutation updateSkills($cID: ID!, $payload: CandidateEditInput!) {
    mutate(type: CandidateMutation, id: $cID) {
      ... on CandidateMutation {
        id
        edit(payload: $payload) {
          id
          skill_ratings {
            id
            score
            skill {
              id
              name
            }
          }
          languages {
            id
            language {
              id
              name
            }
            proficiency
          }
        }
      }
    }
  }
`;

export const UPDATE_LANGUAGES = gql`
  mutation updateLanguages($cID: ID!, $payload: CandidateEditInput!) {
    mutate(type: CandidateMutation, id: $cID) {
      ... on CandidateMutation {
        id
        edit(payload: $payload) {
          id
          languages {
            id
            language {
              id
              name
            }
            modes
          }
        }
      }
    }
  }
`;

export const UPDATE_NOTICE_PERIOD = gql`
  mutation updateNoticePeriod($cID: ID!, $payload: CandidateEditInput!) {
    mutate(type: CandidateMutation, id: $cID) {
      ... on CandidateMutation {
        id
        edit(payload: $payload) {
          id
          expectation {
            notice_period
            preferred_cities {
              id
              name
            }
          }
        }
      }
    }
  }
`;

export const LIST_LANGUAGES = gql`
  query languageList {
    listLanguages {
      id
      name
    }
  }
`;

export const GET_CITIES = gql`
  query getCities($query: String!) {
    searchCity(query: $query) {
      id
      name
      city_latitude
      city_longitude
      state {
        id
        name
      }
    }
  }
`;

export const GET_LOCALITY_NEAR_PLACE = gql`
  query getLocalityNearPlace($lat: Float, $long: Float) {
    searchLocality(query_type: LOCATION, latitude: $lat, longitude: $long) {
      id
      name
    }
  }
`;

export const UPDATE_NOTIFICATION_SETTINGS = gql`
  mutation updateNotificationSettings(
    $cID: ID!
    $payload: CandidateEditInput!
  ) {
    mutate(type: CandidateMutation, id: $cID) {
      ... on CandidateMutation {
        id
        edit(payload: $payload) {
          id
          user {
            id
            email_subscribed
            sms_subscribed
            whatsapp_subscribed
            whatsapp_chatbot_subscribed
          }
        }
      }
    }
  }
`;

export const UPDATE_PROFILE_SUMMARY = gql`
  mutation updateProfileSummary($cID: ID!, $payload: CandidateEditInput!) {
    mutate(type: CandidateMutation, id: $cID) {
      ... on CandidateMutation {
        id
        edit(payload: $payload) {
          id
          user {
            id
            first_name
            last_name
            date_of_birth
            age
            gender
          }
        }
      }
    }
  }
`;

export const UPDATE_WORK_LOCATION = gql`
  mutation updateWorkLocation($cID: ID!, $payload: CandidateEditInput!) {
    mutate(type: CandidateMutation, id: $cID) {
      ... on CandidateMutation {
        id
        edit(payload: $payload) {
          id
          address {
            id
            locality {
              id
              name
              latitude
              longitude
              city {
                id
                name
              }
            }
          }
        }
      }
    }
  }
`;

export const UPDATE_SALARY_DETAILS = gql`
  mutation updateSalaryDetails($cID: ID!, $payload: CandidateEditInput!) {
    mutate(type: CandidateMutation, id: $cID) {
      ... on CandidateMutation {
        id
        edit(payload: $payload) {
          id
          salary {
            id
            current_salary
            current_salary_format
            expected_salary
            expected_salary_format
          }
          expectation {
            notice_period
            preferred_cities {
              id
              name
            }
          }
        }
      }
    }
  }
`;

export const UPDATE_PREFERRED_LOCATIONS = gql`
  mutation updatePreferredLocations($cID: ID!, $preferredCities: [Int!]) {
    mutate(type: CandidateMutation, id: $cID) {
      ... on CandidateMutation {
        id
        edit(payload: { expectation: { preferred_cities: $preferredCities } }) {
          id
          expectation {
            notice_period
            preferred_cities {
              id
              name
            }
          }
        }
      }
    }
  }
`;

export const ADD_USER_EDUCATION = gql`
  mutation addEducation($cID: ID!, $payload: CandidateEducationInput!) {
    mutate(type: CandidateMutation, id: $cID) {
      ... on CandidateMutation {
        id
        education {
          add(payload: $payload) {
            id
            institute
            place {
              short_formatted_address
            }
            year_of_passing
            degree
            specialization
            proficiency_level
          }
        }
      }
    }
  }
`;

export const UPDATE_USER_EDUCATION = gql`
  mutation updateEducation(
    $cID: ID!
    $educationID: Int!
    $payload: CandidateEducationInput!
  ) {
    mutate(type: CandidateMutation, id: $cID) {
      ... on CandidateMutation {
        id
        education {
          edit(id: $educationID, payload: $payload) {
            id
            institute
            place {
              short_formatted_address
            }
            year_of_passing
            degree
            specialization
            proficiency_level
          }
        }
      }
    }
  }
`;

// export const DELETE_USER_EDUCATION = gql`
//   mutation deleteEducation($educationID: Int!, $cID: ID!) {
//     mutate(type: CandidateMutation, id: $cID) {
//       ... on CandidateMutation {
//         id
//         education {
//           delete(id: $educationID)
//         }
//       }
//     }
//   }
// `;

export const ADD_USER_EXPERIENCE = gql`
  mutation addExperience($cID: ID!, $payload: CandidateWorkHistoryInput!) {
    mutate(type: CandidateMutation, id: $cID) {
      ... on CandidateMutation {
        id
        experience {
          add(payload: $payload) {
            id
            functional_area {
              id
              name
            }
            job_title
            started_from
            worked_till
            till_now
            work_description
            company_name
          }
        }
      }
    }
  }
`;

export const UPDATE_USER_EXPERIENCE = gql`
  mutation updateExperience(
    $cID: ID!
    $experienceID: Int!
    $payload: CandidateWorkHistoryInput!
  ) {
    mutate(type: CandidateMutation, id: $cID) {
      ... on CandidateMutation {
        id
        experience {
          edit(id: $experienceID, payload: $payload) {
            id
            functional_area {
              id
              name
            }
            job_title
            started_from
            worked_till
            till_now
            work_description
            company_name
          }
        }
      }
    }
  }
`;

export const DELETE_USER_EXPERIENCE = gql`
  mutation deleteExperience($experienceID: Int!, $cID: ID!) {
    mutate(type: CandidateMutation, id: $cID) {
      ... on CandidateMutation {
        id
        experience {
          delete(id: $experienceID)
        }
      }
    }
  }
`;

export const UPDATE_USER_LOCATION = gql`
  mutation updateLocation($cID: ID!, $locationID: Int) {
    mutate(type: CandidateMutation, id: $cID) {
      ... on CandidateMutation {
        id
        edit(payload: { address: { locality_id: $locationID } }) {
          id
          address {
            id
            place {
              place_id
              location
            }
            locality {
              id
              name
              latitude
              longitude
              city {
                id
                name
              }
            }
          }
        }
      }
    }
  }
`;

export const UPDATE_USER_MOBILE = gql`
  mutation updateMobile(
    $oldMobile: String!
    $newMobile: String!
    $otp: String!
  ) {
    updateUserMobile(old_mobile: $oldMobile, new_mobile: $newMobile, otp: $otp)
  }
`;

export const SEND_OTP = gql`
  mutation UserRequestOTP($user_id: ID, $mobile: String!) {
    userRequestOtp(user_id: $user_id, mobile: $mobile, type: ACTIVATION_CODE)
  }
`;

export const UPDATE_MOBILE_INFO = gql`
  mutation UpdateMobileInfo(
    $userId: UUID!
    $primaryMobile: String
    $delinkMobiles: [String]
  ) {
    updateMobileInfo(
      id: $userId
      primary_mobile: $primaryMobile
      delink_mobiles: $delinkMobiles
    )
  }
`;

export const ADD_ALTERNATE_MOBILE_NUMBER = gql`
  mutation AddAlternateMobile($userId: UUID!, $alternate_mobile: [String]) {
    add_alternate_numbers(id: $userId, alternate_mobile: $alternate_mobile)
  }
`;

export const VERIFY_USER_MOBILE = gql`
  mutation VerifyUserMobile(
    $userId: UUID!
    $otp: String!
    $clientId: String!
    $mobile: String
  ) {
    verifyUserMobile(
      user_id: $userId
      otp: $otp
      client_id: $clientId
      mobile_number: $mobile
    ) {
      ... on User {
        id
        first_name
        last_name
        email
        mobile
        user_type
        mobile_verified
        email_verified
        password_exists
        alternate_mobile
        verified_details {
          mobile
          verified_by_candidate
        }
      }
    }
  }
`;

export const USER_REWARD_DETAILS = gql`
  query fetchUserRewardDetails {
    fetchUserRewardDetails {
      number_of_referrals
      total_rewards
      rewards {
        id
        name
        amount
        order
        status
        description1
        description2
        description3
      }
    }
  }
`;

export const GET_REFERRED_USERS = gql`
  query getSuccessfullReferredUserList {
    referredUserDetails {
      user_id
      username
      photo
      gender
      mobile_no
      __typename
    }
  }
`;

export const USER_TOTAL_REWARDS = gql`
  query fetchUserTotalRewards {
    userCompletedMilestones {
      id
      name
      amount
      order
      status
      description1
      description2
      description3
      __typename
    }
  }
`;

export const VPA_VALIDATION_VERIFY = gql`
  query VerifyUPIId($vpaId: String!) {
    verifyUPIId(vpa_id: $vpaId) {
      status
      message
      name_at_bank
      account_exists
      __typename
    }
  }
`;

export const CREATE_TRANSFER_REQUEST = gql`
  mutation makeReferralPayout($payload: MakeReferralPayoutInput!) {
    makeReferralPayout(payload: $payload) {
      ... on MakeReferralPayoutResponse {
        reward_id
        payment_id
        amount
        utr_number
        transfer_acknowledged_status
        message
        status
      }
    }
  }
`;
